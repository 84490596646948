.Sources {
  list-style: none;
  display: flex;
  padding: 0;
  margin: 0;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  text-align: right;
  flex-wrap: wrap;
  margin-bottom: 30px;
}

.Sources-title {
  font-size: 14px;
}

.Source {
  line-height: 2em;
  padding: 0 10px;
  background-color: #fff;
  border: 1px solid #ccc;
  margin: 10px 0 10px 10px;
  border-radius: 5px;
}

.Source a {
  text-transform: uppercase;
  color: #333;
  text-decoration: none;
  white-space: nowrap;
  font-size: 12px;
}

.Source a svg {
  vertical-align: baseline;
}
