.Header {
  position: fixed;
  background-color: #fff;
  border-bottom: 1px solid #dfdfdf;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.05);
  top: 0;
  left: 0;
  width: 100%;
  z-index: 9000;
}

.Header.Header--isTransparent {
  background-color: rgba(0, 0, 0, 0.6);
  border-bottom: none;
  box-shadow: none;
}

.Header-wrapper > .row {
  align-items: center;
  height: 88px;
}

.Header-logo > * {
  max-width: 100%;
  height: auto;
}

.Header.Header--isTransparent .Header-logo svg {
  filter: brightness(100);
}

.Header-menu {
  display: flex;
  justify-items: end;
  align-items: center;
  flex-direction: row;
  justify-content: flex-end;
  list-style: none;
  margin: 0 0 0 0;
}

.Header-menuItem {
  position: relative;
  padding: 0 0 0 32px;
}

.Header-menuItem,
.Header-menuItem a {
  font-size: 17px;
  text-decoration: none;
  color: #333;
  display: block;
}

.Header-menuItem a {
  padding: 5px 0;
}

.Header.Header--isTransparent
  .Header-wrapper
  > .row
  > div
  > .Header-menu
  > .Header-menuItem,
.Header.Header--isTransparent
  .Header-wrapper
  > .row
  > div
  > .Header-menu
  > .Header-menuItem
  > a {
  color: #fff;
}

.Header-menuItem a:hover {
  text-decoration: none;
  font-weight: 600;
}

.Header-menuItem > .Header-menu .Header-menuItem:last-child {
  border-bottom: 0;
}

.Menu-icon {
  display: none;
}

.Menu-button {
  box-sizing: border-box;
  font-size: 30px;
  background-color: transparent;
  border: 0;
  text-align: right;
  display: none;
  color: #333;
}

.Header.Header--isTransparent .Menu-button {
  color: #fff;
}

@media all and (min-width: 768px) {
  .Header-menuItem > .Header-menu {
    position: absolute;
    flex-direction: column;
    top: 100%;
    left: 0;
    min-width: 184px;
    align-items: flex-start;
    display: none;
  }

  .Header-hasSubmenu:hover > .Header-menu {
    display: block;
    padding: 16px 0;
    background: #fff;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.05);
  }

  .Header-menuItem > .Header-menu .Header-menuItem {
    text-align: left;
    border-bottom: 1px solid #dfdfdf;
    padding: 0 16px;
  }
}

@media all and (max-width: 768px) {
  .Menu-icon {
    display: flex;
  }

  .Menu-button {
    display: inherit;
  }

  .Header-wrapper > .row {
    height: 10vh;
  }

  .Header .Menu {
    position: fixed;
    top: calc(10vh + 1px);
    left: 0;
    height: 0;
    overflow: hidden;
    background-color: #fff;
  }

  .Header.Header--isTransparent .Menu {
    background-color: rgba(0, 0, 0, 0.8);
  }

  .Header .Menu.Menu--isOpened {
    height: calc(90vh - 1px);
    overflow: scroll;
  }

  .Header-menu {
    flex-direction: column;
    padding: 0 0 0 0;
  }

  .Header-menuItem {
    border-bottom: 1px solid #efefef;
    width: 100%;
    text-align: center;
    padding: 8px 0;
  }

  .Header-menuItem > .Header-menu .Header-menuItem:last-child {
    padding-bottom: 0;
  }

  .Header .Menu > .Header-menu > .Header-menuItem {
    background-color: #fafafa;
    border: 0;
    margin: 8px 0 0;
    padding: 8px;
  }

  .Header.Header--isTransparent .Menu > .Header-menu > .Header-menuItem {
    background-color: rgba(0, 0, 0, 0.8);
    color: #fff;
  }

  .Header-menuItem:last-item {
    border-bottom: none !important;
  }

  .Header-menuItem > .Header-menu {
    position: inherit;
    display: block;
    width: 100%;
  }

  .Header-menuItem > .Header-menu a {
    font-size: 0.9em;
  }

  .Header.Header--isTransparent .Header-menuItem > .Header-menu a {
    color: #fff;
  }
}
