.ComparisonValue {
  border-width: 15px 3px 3px 3px;
  border-style: solid;
  border-color: transparent;
  border-radius: 3px;
}

.ComparisonValue.ComparisonValue-best {
  border-color: #76ba99;
}
