.TopContent {
  background-image: url(../../assets/images/Header.jpeg);
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  text-align: right;
  font-size: 5rem;
  line-height: 1em;
  color: #fff;
  padding: calc(88px + 10vw) 15px 10vw;
  box-sizing: border-box;
  text-shadow: 0px 0px 10px rgba(0, 0, 0, 40%);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: bottom right;
}

.TopContent > * {
  max-width: 1200px;
  width: 100%;
  margin: 0 auto;
}

.TopContent b {
  display: block;
}

.Home .Content-wrapper {
  margin-top: 40px;
}

@media screen and (max-width: 998px) {
  .TopContent {
    margin: 0 0;
  }

  .TopContent b {
    display: inline;
  }

  .Home .Content-wrapper {
    margin-top: 20px;
  }
}
