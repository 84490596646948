.IntervalPicker {
  justify-content: flex-end;
  margin-bottom: 30px;
}

.IntervalPicker-icon {
  text-align: right;
}

.IntervalPicker .IntervalPicker-input {
  line-height: 2em;
  height: auto;
  padding: 0 10px;
  border-radius: 4px;
  border: 1px solid #ddd;
  width: 100%;
  box-sizing: border-box;
}
